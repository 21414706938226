import moment from 'moment-timezone'

export default {
	init: (utils) => {
		// Old way to get the timezone (the GMT)
		// Saved for too long + doesn't work when changing hour winter/summer
		const browser_timezone = utils.getCookie('browser_timezone');
		if (!browser_timezone) {
			const browser_time = new Date();
			const GMT_diff = -browser_time.getTimezoneOffset()/60;
			let browser_timezone = "GMT";
			if (GMT_diff > 0) {
				browser_timezone += "+" + GMT_diff;
			} else if (GMT_diff < 0) {
				browser_timezone += "-" + GMT_diff;
			} else {
				browser_timezone += "0";
			}
			utils.setCookie('browser_timezone', browser_timezone, 1000);
		}

		// Get the text TimeZone like 'Europe/Paris'
		// It's better as we can manage the winter/summer time
		const browser_tz_guess = utils.getCookie('browser_tz_guess');
		if (!browser_tz_guess) {
			utils.setCookie('browser_tz_guess', moment.tz.guess(true), 30);
		}
	}
}