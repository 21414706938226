export default {
	init: function(utils) {

        this.searchCardButtons = document.querySelectorAll('.js-search-facetCard');
        this.handleButtonsClick()

        body.addEventListener('facets:reset', () => {
            this.handleFacetsReset();
        })

	},

    handleButtonsClick: function(){
        if (this.searchCardButtons.length) {
            this.searchCardButtons.forEach(button => {
                const list_id = button.dataset.listId ? button.dataset.listId : null;
                const searchable_type = button.dataset.searchableType ? button.dataset.searchableType : null;
                const is_favorite = button.dataset.favorite === 'true' ? true : false

                button.addEventListener('click', (e) => {
                    e.preventDefault();
                    this.getPostTypeDatasAjax(list_id, searchable_type, is_favorite);
                    this.setButtonsClass(button);

                    this.setQueryParameters({
                        'searchable_type': searchable_type,
                        'list_id': list_id
                    });
                })
            })
        }

    },

    /**
     * 
     * @param {Number} list_id : id of list page
     * @param {String} searchable_type : type of post to search on
     * @param {Boolean} only_favorites : display only favorite
     */
    getPostTypeDatasAjax: function(list_id, searchable_type, only_favorites){

        const listContainer = document.querySelector('.js-list');
        const facetsContainer = document.querySelector('.js-list-side')

        const event = new CustomEvent("dom:updated");
        //Used for favorites
        const event_list = new CustomEvent("list:updated");
        const body = document.querySelector('body')

        this.clearQueryParameters();

        let ajax_datas = {
            action: 'fetch_posts_and_facets',
            list_id: list_id,
            searchable_type: searchable_type,
            url: window.location.href
        }

        if (only_favorites === true) {
            ajax_datas.only_favorites = true
        }

        $.ajax({
            url: wordpress_vars.ajax_url,
            type: 'POST',
            data: ajax_datas,
            beforeSend: function(){
                listContainer.classList.add('--loading')
                facetsContainer.classList.add('--loading')
            },
            success: function(response) {
                if (response.data.facets_html) {
                    facetsContainer.innerHTML = response.data.facets_html
                }

                if (response.data.post_list_html) {
                    listContainer.innerHTML = response.data.post_list_html
                    //Manage list attribute
                    listContainer.classList.remove(listContainer.dataset.searchType)
                    listContainer.classList.add(searchable_type);
                    listContainer.dataset.searchType = searchable_type
                    this.currentSearchableType = searchable_type;
                }

                const loadMoreButton = document.querySelector('.js-load-more');
                if (loadMoreButton) {
                    if (response.data.hide_load_more_button === false) {
                        loadMoreButton.classList.add('--hidden')
                    } else {
                        loadMoreButton.classList.remove('--hidden')
                    }
                }
                
                body.dispatchEvent(event);
                body.dispatchEvent(event_list);
            },
            complete: function(){
                listContainer.classList.remove('--loading')
                facetsContainer.classList.remove('--loading')
            },
            error: function(xhr, status, error) {
                console.error('AJAX Error:', status, error);
            }
        });

    },

    /**
     * 
     * @param {HTMLButtonElement} button 
     */
    setButtonsClass: function(button){

        this.searchCardButtons.forEach(button => {
            button.classList.remove('--selected')
        })

        button.classList.add('--selected')

    },

    handleFacetsReset: function(){
        const searchFacetCard = document.querySelector('.js-search-facetCard.--selected')

        if (!searchFacetCard) {
            return;
        }

        const list_id = searchFacetCard.dataset.listId ? searchFacetCard.dataset.listId : null;
        const searchable_type = searchFacetCard.dataset.searchableType ? searchFacetCard.dataset.searchableType : null;

        this.setQueryParameters({
            'searchable_type': searchable_type,
            'list_id': list_id
        })
    },

    clearQueryParameters: function() {
        const url = new URL(window.location.href);
        const keywords = url.searchParams.get('r');
    
        url.searchParams.forEach((_, key) => {
            url.searchParams.delete(key);
        });
    
        if (keywords !== null) {
            url.searchParams.set('r', keywords);
        }
    
        history.pushState({}, "", url.toString());
    },

    /**
     * 
     * @param {Object} params 
     */
    setQueryParameters: function(params){
        const url = new URL(location);

        Object.entries(params).forEach(([key, value]) => {
            if (value === null) {
                url.searchParams.delete(key);
            } else {
                url.searchParams.set(key, value);
            }
        });

        history.pushState({}, "", url.toString());
    }

}